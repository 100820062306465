import { Action } from '@ngrx/store'
import { DateRange } from 'models/date-range'

export enum AnalyticsActionType {
  SET_DATE_FROM = '[ANALYTICS] SET DATE FROM',
}

export class SetDateFrom implements Action {
  readonly type = AnalyticsActionType.SET_DATE_FROM

  constructor(public payload: DateRange) {}
}

export type AnalyticsAction = SetDateFrom
